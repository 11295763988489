const nlLocale = {
  appTitle: 'Watersport',
  version: 'versie %{version}',
  save: 'Opslaan',
  cancel: 'Annuleer',
  affirm: 'Begrepen',
  select: 'Selecteer',
  generalError:
    'Er is helaas iets fout gegaan, probeer het later opnieuw of neem contact op met support@teqplay.nl.',
  now: 'Nu',
  yes: 'ja',
  no: 'nee',
  ok: 'OK',
  latitude: 'Breedtegraad',
  longitude: 'Lengtegraad',

  units: {
    kmh: 'km/h',
    knots: 'knopen',
    kilowatt: 'kW',
    amps: 'A'
  },

  locale: {
    selectLocale: 'Taal',
    nl_NL: 'Nederlands',
    en_GB: 'Engels',
    de_DE: 'Duits',
    nl_FRL: 'Fries',

    nl_NL_native: 'Nederlands',
    en_GB_native: 'English',
    de_DE_native: 'Deutsch',
    frl_NL_native: 'Frysk'
  },

  login: {
    anonymously: 'Ga verder zonder account',
    welcome: 'Welkom, log in om verder te gaan.',
    email: 'E-mail',
    password: 'Wachtwoord',
    submit: 'Login',
    noUser: 'Nog geen gebruiker:',
    policy: 'Privacy policy',
    registerLink: 'Registreer',
    wrongCredentials: 'De gebruikersnaam of het wachtwoord is onjuist',
    wrongAccountType: 'Uw accounttype is ongeschikt voor de Watersport app',
    forgotPassword: 'Wachtwoord vergeten?',
    forgotpassemail: 'Geef uw email op om uw wachtwoord te laten resetten',
    forgotpassconfim:
      'Uw verzoek is ontvangen, wij zullen dit zo snel mogelijk afhandelen en via uw mail contact opnemen.',
    send: 'Verstuur',
    newAppMessage:
      'Watersport is vernieuwd! Accounts gemaakt voor oktober 2020 kunnen mogelijk niet worden gebruikt om in te loggen. Maak een nieuw account aan.'
  },
  // Possibilty to add translations
  policy: {
    permission:
      'When you create an account for this Watersports app, you are granting permission for the processing of your data. We want you to understand what data we collect and how we use it.',
    storage:
      'When you sign up for a Watersports account, we store the account information you provide. This includes your vessel name, name, email address, and password. We also retain information about your location and your device data.',
    dataCollection:
      'When you use the Watersports app, we collect data to provide and improve our services. Your data is accessible to the Province of Friesland, other waterway authorities, and the app administrator, Teqplay. The purpose of using your data is to allow you, as a waterway user, to make the best possible use of the Frisian waterways. Your data is used to deliver our service and manage your user account.',
    privacyManagement:
      'You can manage your privacy in the Watersports app. You can access your data and delete your account at any time. After the termination of your account, personal data is immediately deleted. However, we retain location data for our statistics. Personal data is retained as long as you have an account.',
    dataProtection:
      "We protect your data and our applications against unauthorized use. That's why we encrypt many of our services with SSL and evaluate our approach to data collection, storage, and processing. Access to personal data is limited to employees and waterway authorities who need this data to provide our service.",
    lastUpdated:
      "Last updated: 26th October 2023 Teqplay ('us', 'we', or 'our') operates Watersport (the 'platform'). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the platform. We use your Personal Information only for providing and improving the platform. By using the platform, you agree to the collection and use of information in accordance with this policy.",
    personal:
      'Personal information: We collect some personal information that makes us able to separate one user from the other. This information is used to create your account in our systems and may include, but is not limited to your name, E-Mail address and mobile phone number',
    device:
      'Device information: We collect a specific set of device information. This information includes information about the hardware model, the version of the running operating system, unique device identifiers and the status of the wireless network.',
    account:
      'Account information: Communication is a core functionality of the platform. By sending you notifications we can let you know about important events that take place. In order to do this we need to know the Google account identifier that belongs to the Google account you use on your phone.',
    thirdParties: 'Third parties:',
    ageRestriction:
      'Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s why we do not knowingly collect personal information from anyone under 13.',
    cookies:
      "Like many websites and platforms, we use 'cookies' to collect information. We use these cookies to prevent you from having to log in each time you use the platform, for example.",
    security:
      'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.',
    changesToPrivacyPolicy:
      'we reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the platform after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.',
    contactUs:
      'If you have any questions about this Privacy Policy, please contact us. For our contact details:',
    title: {
      children: 'Children',
      cookies: 'Cookies',
      security: 'Security',
      contactUs: 'Contact us',
      changes: 'Changes to this Privacy Policy',
      watersport: 'Watersport Privacy Policy'
    },
    back: 'Back'
  },

  anonymous: {
    notAllowed:
      'Om deze functie te gebruiken moet u ingelogd zijn. Klik op "Maak een account aan" om een gratis account aan te maken',
    createAccount: 'Maak een account aan',
    cancel: 'Later'
  },

  shipTypes: {
    select: 'Selecteer scheepstype',
    MOTOR_YACHT: 'Motorjacht',
    SPEEDBOAT: 'Speedboot',
    SAILING: 'Zeilschip',
    SAILING_WITH_MOTOR: 'Zeilschip met motor',
    SPORT_FISHERMEN_VESSEL: 'Vaartuig voor sportvissers',
    SAILING_MOTOR_BIG: 'Zeil- of motorschip groter dan 20m',
    OTHER_RECREATIONAL_VESSEL: 'Overig recreatievaartuig'
  },

  register: {
    createAccount: 'Registreer een account',
    submit: 'Registreer',
    done: 'Gelukt!',
    backToLogin: 'Terug naar login',
    emailAddress: 'E-mail',
    password: 'Wachtwoord',
    repeatPassword: 'Herhaal wachtwoord',
    phoneNumber: 'Telefoonnummer',
    shipName: 'Scheepsnaam',
    shipType: 'Scheepstype',
    length: 'Lengte schip (m)',
    width: 'Breedte schip (m)',
    height: 'Hoogte schip (m)',
    draught: 'Kieldiepte schip (m)',
    dimensionsHead: 'Afmetingen schip',
    pleaseFillRealistic: 'De scheepsafmetingen moeten tussen de 0 en 100 m zijn',
    passwordsNotMatch: 'De wachtwoorden zijn niet gelijk',
    fieldsRequired: 'Vul alle verplichte velden in (*)',
    alreadyExists: 'Er is al een account voor dit e-mailadres',
    emailInvalid: 'Uw ingevulde emailadres is ongeldig',
    failedToFetch: 'Kan geen verbinding maken met de server. Controleer uw internetverbinding.',
    serverError:
      'Er is een onbekende fout opgetreden, probeer het opnieuw of neem contact op met support@teqplay.nl',
    gainedAccessToVarenFriesland:
      'Uw reeds bestaande Teqplay account heeft nu toegang tot Watersport. Login met uw gegevens of vraag een nieuw wachtwoord aan via de wachtwoord vergeten knop met hetzelfde emailadres.',

    gdpr: {
      title: 'Privacyverklaring',
      privacyBody:
        'Als u een account aanmaakt voor deze Watersport-app, dan geeft u daarmee toestemming voor het verwerken van uw gegevens. We willen graag dat u begrijpt welke gegevens we verzamelen en hoe we die gebruiken.' +
        '<br /><br />Wanneer u zich aanmeldt voor een Watersport-account, bewaren we de accountgegevens die u opgeeft. Dit zijn uw scheepsnaam, naam, e-mailadres en wachtwoord. Ook bewaren we informatie over uw locatie en uw apparaatgegevens.' +
        '<br /><br />Wanneer u de Watersport-app gebruikt verzamelen we gegevens om onze diensten te kunnen leveren en verbeteren.' +
        '<br /><br />Uw gegevens zijn beschikbaar voor provincie Fryslân, voor andere vaarwegbeheerders en de appbeheerder Teqplay. Doel van het gebruik van uw gegevens is om u als vaarweggebruiker zo optimaal mogelijk gebruik te laten maken van de Friese vaarwegen. Uw gegevens worden gebruikt voor het leveren van onze service en het beheren van uw gebruikersaccount.' +
        '<br /><br />U kunt zelf in de Watersport-app uw privacy beheren. U kunt op elke moment uw gegevens zelf inzien en uw account verwijderen. Na beëindiging van uw account worden de persoonlijke gegevens onmiddellijk verwijderd. Wel bewaren we locatiegegevens voor onze statistieken.  Persoonsgegevens worden bewaard zolang als u een account heeft.' +
        '<br /><br />We beschermen uw en onze gegevens en applicaties tegen onbevoegd gebruik. Daarom coderen we veel van onze services met SSL en evalueren we onze manier van werken met betrekking tot verzameling, opslag en verwerking van gegevens. We beperken de toegang tot persoonlijke gegevens tot medewerkers en vaarwegbeheerders die deze gegevens nodig hebben om onze dienst te kunnen leveren.' +
        '<br /><br />Voor vragen over deze disclaimer kunt u contact opnemen met: provincie@fryslan.frl',
      accept: 'Accepteren',
      deny: 'Weigeren',
      error: 'U moet de privacyvoorwaarden accepteren om de Watersport app te kunnen gebruiken.',
      view: 'Lees voorwaarden',
      close: 'Sluiten'
    }
  },

  routeSelection: {
    routeNumbered: 'Route %{routeNumber}',
    selectRoutePlaceholder: 'Selecteer uw reis',
    startingPoint: 'Vertrekpunt',
    destinationPoint: 'Bestemming',
    selectDestination: 'Selecteer uw bestemming',
    selectStartingPoint: 'Selecteer uw vertrekpunt',
    selectViaPoint: 'Selecteer een via punt',
    viaPoint: 'Via punt',
    options: 'Opties',
    calculateRoute: 'Bereken route',
    arrival: 'Aankomst',
    departure: 'Vertrek',
    searchOnLocation: 'Zoeken op locatie',
    noOptions: 'Geen locaties gevonden',
    searchAdvice: 'Zoek op locatie',
    currentLocation: 'Huidige locatie',
    selectOnMap: 'Selecteer locatie op de kaart',
    distance: 'Afstand',
    arrivalTime: 'Aankomsttijd',
    viewList: 'Lijst Weergave',
    startRoute: 'Start route',
    deleteRoute: 'Verwijder route',
    saveRoute: 'Opslaan',
    cancel: 'Annuleren',
    list: 'Objecten',
    details: 'Info',
    map: 'Kaart',
    notAllowedDimensions: 'Deze route is niet vaarbaar met ingevoerde scheepsafmetingen',
    notAllowedBridge: 'Niet alle bruggen zijn te passeren met de ingevoerde scheepshoogte',
    noRoutesFoundWarning: 'Er zijn geen routes gevonden voor dit traject',
    noDetailsFoundWarning: 'Geen details voor dit object beschikbaar',
    routeDetails: 'Route informatie',
    routeStoredSucces:
      'De route is opgeslagen. Bij het selecteren van de locaties ziet u de opgeslagen routes.',
    showSavedRoutes: 'Toon opgeslagen routes',
    hideSavedRoutes: 'Verberg opgeslagen routes',
    fromTo: '%{from} naar %{to}',
    dateSaved: 'Opgeslagen op',
    saveRouteTitle: 'Opslaan van route',
    noNameFilled: 'Vul een naam in om de route op te slaan',
    routeNameInput: 'Naam route',
    selectionWarning: 'Selecteer een vertrekpunt en bestemming',
    pauseRoute: 'Pauzeer Route',
    resumeRoute: 'Vervolg Route',
    noLocationWarning: 'Zet uw GPS aan om de route te kunnen starten.',
    shipDepth: 'Ondiepste punt',
    shipHeight: 'Laagste doorvaarhoogte',
    maxWidth: 'Smalste punt',
    waitingTime: 'Verwachte wachttijd',
    duration: 'Totale duur',
    createGeolocation: '"%{value}"',
    node: '%{value} (Vaarknooppunt)'
  },

  routeList: {
    itemDetails: {
      opening_required: 'Een brugopening is nodig voor uw schip. Vraag zelf de brugopening aan.',
      opening_required_bms_bridge:
        'Een brugopening is nodig voor uw schip. Deze wordt automatisch aangevraagd.',
      cannot_pass_bridge: 'Let op! Uw schip past niet onder deze brug door.',
      opening_requested: 'Er is een brugopening aangevraagd',
      opening_planned: 'De brug gaat binnenkort open',
      communication: 'Communicatie',
      vhf: 'VHF',
      control_times: 'Bedientijden',
      yes: 'Ja',
      no: 'Nee',
      NAP: 'NAP',
      opening: 'Opening',
      type: 'Type',
      opening_width: 'Breedte',
      width: 'Breedte',
      length: 'Lengte',
      closed_height: 'Gesloten hoogte',
      opened_height: 'Geopende hoogte',
      telephone: 'Tel',
      lock_chamber: 'Sluiskom',
      max_width: 'Max. Breedte (m)',
      threshold_outside: 'Drempel beneden/buiten',
      threshold_inside: 'Drempel boven/binnen',
      address: 'Waladres',
      from: 'Van',
      to: 'tot',
      wholeDay: 'Hele dag',
      bridge_type: {
        VST: 'Vast',
        DR: 'Draai',
        BC: 'Bascule',
        OPH: 'Ophaal',
        HEF: 'Hef',
        DBC: 'DBC'
      },
      bridge_status: {
        open: 'Deze brug is op dit moment geopend',
        closed: 'Deze brug is op dit moment dicht.',
        unknown: 'De actuele status van deze brug is onbekend.'
      },
      bridge_operating_rules: {
        today: 'Vandaag',
        tomorrow: 'Morgen',
        holiday: 'Feestdagen',
        noService: 'Geen bediening'
      },
      bridgeRecommendation: {
        AFSC: 'Afschutten',
        AFVT: 'Afvaart',
        BERP: 'Uitsluitend voor beroepsvaart',
        BSCH: 'Begeleide scheepvaart',
        BVAF: 'Uitsluitend beroepsvaart op afroep',
        ETAA: 'Eindtijd afhankelijk aanbod',
        FACO: 'Facultatieve opening',
        GEEN: 'Geen bediening',
        HAR: 'Alleen richting Haringvliet',
        KAN: 'Alleen richting kanaal',
        KONV: 'Konvooivaart',
        OPEN: 'Staat (in het algemeen) open',
        OPSC: 'Opschutten',
        OPVT: 'Opvaart',
        RECR: 'Recreatievaart',
        STDU: 'Alleen de stad uit',
        VERZ: 'Alleen op afroep',
        ZELF: 'Zelfbediening',
        ZUID: 'Alleen zuidgaand'
      },
      no_items_found: 'Geen details voor dit object beschikbaar',
      waitingTime: '%{waitingTime} wachttijd verwacht voor volgende %{objectType}',
      note: 'Opmerking',
      bridgeOpeningNote: 'Opmerking over brugopening',
      chamberNote: 'Opmerking over sluiskom',
      waterLevel: 'Waterhoogte',
      operable: 'Bedienbaar',
      phoneNumber: 'Telefoonnummer',
      moreInfo: 'Meer informatie'
    },
    objectTypes: {
      BRIDGE: 'brug',
      LOCK: 'sluis',
      START: 'start',
      END: 'einde',
      NOTIFICATION: 'melding',
      WATERMETER: 'watermeter',
      TRAFFIC_SIGN: 'verkeersbord',
      BOTTLENECK: 'vernauwing',
      VHFSECTOR: 'VHF sector',
      UNKNOWN: 'onbekend object'
    }
  },

  navigationPage: {
    destination: 'Bestemming',
    arrival: 'Aankomst',
    next_object: 'Volgend Object',
    no_user_location_warning: 'Geen GPS Locatie gevonden',
    no_notifications: 'Er zijn geen meldingen voor de huidige vaarlocatie',
    list_view: 'Lijst',
    notifications: 'Notificaties',
    ship_speed: 'Snelheid',
    route_paused_warning: 'Route Gepauzeerd',
    stop_route_confirmation: 'Weet u zeker dat u de route wil stoppen?',
    pause_route: 'Pauzeer route',
    stop_route: 'Stop route',
    resume_route: 'Hervat route'
  },

  modalSelectPreviousRoute: {
    question: 'Wilt u verdergaan met uw vorige route?',
    yes: 'ja',
    no: 'nee'
  },

  pages: {
    welcome: 'Welkom',
    feedback: 'Melding maken',
    navigation: 'Navigatie',
    myProfile: 'Mijn profiel',
    logout: 'Uitloggen',
    loginLink: 'Inloggen',
    register: 'Account registreren',
    policy: 'Privacy Policy'
  },

  map: {
    followButton: 'Centreer schip',
    speedButton: 'Stel snelheid in',
    activelayers: 'Selecteer kaartlagen',

    category: {
      categoryBerths: 'Ligplaatsen',
      categoryFairway: 'Vaarwegen',
      categoryElements: 'Elementen',
      maptype: 'Kaart'
    },

    crossings: 'VDJS Kruispunten',
    public_berths: 'Publieke ligplaatsen',
    berthsOutside: 'Ligplaatsen buitengebied',
    fairways: 'Vaarwegen',
    CEMTClass: 'CEMT Klasse',
    fairwayDepth: 'Diepte',
    recreationalClass: 'Recreatie Klasse',
    electricRoutes: 'Elektrische routes',
    contractDepth: 'Contractdiepte',
    levelDepth: 'Peildiepte',
    inlandHarbours: 'Jachthavens',
    chargingStations: 'Laadpalen',
    bridges: 'Bruggen',
    kilometre: 'Kilometrering',
    trailerSlope: 'Trailerhellingen',
    wasteWaterStations: 'Vuilwater aan de wal',
    nodes: 'Vaarknooppunten',
    winterRestAreas: 'Vogel rustgebieden',
    AISShips: 'Scheepvaart',
    fastRoutes: 'Snelvaargebieden',
    fastRoutesNoInfo: 'Geen informatie beschikbaar',

    speed: {
      title: 'Stel de snelheid in voor het berekenen van aankomsttijden',
      useShip: 'Gebruik snelheid schip',
      useCustom: 'Stel zelf in',
      setRealisticSpeed: 'Stel een snelheid in tussen de 1 en 50 km/h',
      saved: 'De wijziging is opgeslagen'
    },

    details: 'details',

    notification: {
      routeNotifications: 'Vaarweg notificaties',
      tooMuchBerths: 'Te veel ligplaatsen gevonden, zoom verder in om de ligplaatsen te tonen.',
      approachVdjsHeader: 'VDJS Knooppunt',
      approachingVdjsCrossing:
        'Je nadert het knooppunt %{crossingName}. ' +
        'Voor dit knooppunt zijn aangeraden routes beschikbaar van Varen Doe je Samen.',
      approachingVdjsCrossingShort: 'U nadert het knooppunt %{crossingName}',
      show: 'Toon op kaart',
      channelNotificationHeader: 'Drukke Vaarweg',
      nearingChannelNotification:
        'U nadert het %{channelName}. Pas op met varen, er is hier veel beroepsvaart actief.',
      zoomInNotification: 'Zoom in om de volgende kaartlagen op de kaart te zien:'
    }
  },

  myProfile: {
    title: 'Mijn profiel',
    titleProfile: 'Profiel gegevens',
    titlePasswordChange: 'Verander wachtwoord',
    titleShipDimensions: 'Dimensies schip',
    myShip: 'Mijn schip',
    password: 'Wachtwoord',
    email: 'Email',
    mobile: 'Mobiel',
    mmsi: 'MMSI',
    currentPassword: 'Huidig wachtwoord',
    newPassword: 'Nieuw wachtwoord',
    repeatNewPassword: 'Herhaal nieuw wachtwoord',
    save: 'Opslaan',
    newPasswordNoMatch: 'De nieuwe wachtwoorden komen niet overeen',
    updatedPasswordSuccess: 'Je wachtwoord is opgeslagen',
    wrongPassword: 'Verkeerd huidig wachtwoord',
    tooWeak: 'Uw nieuwe wachtwoord is te zwak',
    titleDeleteAccount: 'Profiel verwijderen',
    selectAShip: 'Selecteer een schip',
    updateProfileSuccess: 'Profiel is bijgewerkt',
    updateProfileFailed:
      'Er is iets fout gegaan tijdens het opslaan van jouw profiel, probeer het later opnieuw of neem contact op met support@teqplay.nl',
    areYouSureDeleteAccount:
      'Weet u zeker dat u uw profiel wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    removeSuccess: 'Profiel is verwijderd',
    no: 'Nee',
    yes: 'Ja',
    yesDelete: 'Ja, verwijder',
    dimensionsErrorMessage: '* Dimensies moeten tussen de 0 en 100 meter zijn.',
    dimensionsWarningMessage: '* Vul een waarde in.',
    dimensionsSaveWarning: 'De dimensies voor uw schip zijn niet correct ingevuld.',
    ship: {
      length: 'Lengte (m)',
      width: 'Breedte (m)',
      height: 'Hoogte (m)',
      draught: 'Kieldiepte (m)'
    },

    passwordTips: {
      title: 'Wachtwoord vereisten',
      match: 'Wachtwoorden zijn hetzelfde',
      noMatch: 'Wachtwoorden zijn niet hetzelfde',

      requirements: 'Wachtwoord vereisten',
      requirementsInfo:
        'Wij willen dat uw wachtwoord zo veilig mogelijk is om u en uw gegevens te beschermen. Daarom hanteren wij deze eisen aan uw wachtwoord.',

      tips: 'Tips voor een veilig wachtwoord',
      tipsInfo:
        'U mag de inhoud van uw wachtwoord verder zelf invullen, maar wij hebben ook een aantal tips om het zo veilig mogelijk te maken.',
      tipsCasing: 'Gebruik zowel kleine letters als hoofdletters',
      tipsNumber: 'Gebruik een getal',
      tipsSpecialCharacters: 'Gebruik een speciaal karakter zoals !@#$%',
      tipsLength: 'Gebruik 8 karakters of meer',
      tipsUnique: 'Gebruik een uniek wachtwoord wat je niet overal gebruikt',
      tipsUniqueSubtitle:
        'Dit is natuurlijk niet verplicht en kunnen wij zelfs niet weten of controleren vanwege uw geëncrypte wachtwoord, maar voor de hoogste veiligheidseisen raden wij u aan om een uniek wachtwoord te gebruiken.',
      tipsNoPersonalInfo: 'Gebruik geen makkelijk te raden persoonlijke info in uw wachtwoord',
      tipsStoreSafely: 'Sla uw wachtwoorden veilig op',
      tipsStoreSafelySubtitle:
        'Wij raden het gebruik van een wachtwoord- of passwordmanager aan. U kunt ook het wachtwoord opschrijven en goed verbergen, echter is dit onveiliger dan een wachtwoordmanager.',

      allMet: 'Kijk dit is veilig, goed bezig! 👍',
      abideToAll: 'Uw nieuwe wachtwoord voldoet niet aan de vereisten'
    }
  },

  feedbackPage: {
    title: 'Melding maken',
    description: 'Beschrijving',
    descriptionText: 'Voeg een beschrijving van uw melding toe',
    location: 'Locatie',
    locationText: 'Selecteer een plaats op de kaart waar uw melding of opmerking over gaat.',
    photo: 'Foto',
    photoText: 'Voeg een foto toe voor de betreffende locatie',
    uploadText: 'Sleep of klik om een foto om bij te voegen',
    sendFeedback: 'Melding verzenden',
    warningMessage: 'Vul een omschrijving van uw melding in',
    successMessage: 'Uw melding is verzonden, hartelijk dank voor uw bijdrage!',
    errorMessage: 'Er ging iets fout bij het verzenden van uw melding, excuses voor het ongemak.',
    done: 'Gereed',
    chooseLocation: 'Klik hier om een locatie op de kaart te kiezen',
    noLocationKnown: 'Geen locatie bekend',
    noEmergencies: 'Deze functie is niet voor noodsituaties, bel bij spoed altijd 112.'
  },

  mobile: {
    navigation_active: 'Navigatie actief',
    navigation_active_destination: 'Navigatie naar %{destination} actief',
    offline_title: 'Geen verbinding',
    offline_text: 'Er is geen verbinding, uw route zal worden gepauzeerd.',
    high_speed_title: 'U gaat te snel',
    high_speed_text:
      'Bent u nog aan het varen? U gaat sneller dan 80 km/h, stop de route wanneer u niet meer vaart in de app.'
  },

  mapLayers: {
    last_updated: 'Laatste update',
    past: 'geleden',
    berthLayer: {
      width: 'Breedte',
      length: 'Lengte',
      draught: 'Diepgang',
      berthName: 'Naam ligplaats',
      owner: 'Beheerder',
      dangerousGoodsLevel: 'Kegels',
      mooringOptions: 'Aanleg opties',
      mooringServices: 'Aanleg services',
      mooringFunctions: 'Aanleg functies',
      mooringHourTimeLimit: 'Aanleg tijdslimiet',
      shoreConnection: 'Walverbinding',
      sort: 'Type',
      viewFotoURL: 'Bekijk foto van ligplaats'
    },

    chargingStations: {
      city: 'Stad',
      chargingPointAvailable: 'Oplaadpunt beschikbaar',
      publicAvailable: 'Oplaadpunt publiek',
      greenPower: 'Groene stroom',
      paymentMethod: 'Betaal methode',
      paymentMethods: {
        CASH: 'Contant geld',
        CARD: 'Pin',
        CASH_AND_CARD: 'Contant en pin',
        FREE: 'Gratis',
        UNKNOWN: 'Niet bekend'
      },
      comments: 'Opmerkingen',
      chargingPossibilities: 'Oplaadmogelijkheden',
      unavailable: 'Niet beschikbaar',
      connections: 'aansluitingen'
    },

    inlandHarbours: {
      city: 'Stad',
      shortStayPlaces: 'Kort verblijf plaatsen',
      longStayPlaces: 'Lang verblijf plaatsen',
      suppliesFuel: 'Brandstof verkrijgbaar'
    },

    wasteWaterStations: {
      city: 'Stad',
      address: 'Adres',
      containsWasteWater: 'Bevat vuilwater',
      bilge: 'Bilge',
      phone: 'Telefoon'
    }
  },

  pushNotification: {
    buttonClose: 'Oke'
  },

  geolocationStrings: {
    warning: 'Waarschuwing',
    info: 'Informatie',
    deniedPermission: 'De app heeft geen toestemming om uw locatie te gebruiken.',
    clickToRetry: 'Klik om opnieuw te proberen',
    stillDenied:
      'App heeft nog steeds geen toestemming, ga naar Instellingen > Apps > Watersport > Rechten/Permissies en zet Locatiebepaling aan',
    clickToMinimize: 'Klik om te minimaliseren',

    androidWhenActive: 'Locatiebepaling actief',
    androidChannelName: 'GPS locatiebepaling actief',
    androidPermissionDeniedInfo: 'Zonder locatiebepaling heeft de app beperkte functionaliteit',

    whenInUse:
      'Om locatiebepaling te gebruiken in de achtergrond en actuele notificaties te krijgen, moet u voor deze app voor "Locatie" toestemming geven om "Altijd" te gebruiken.',
    instructions:
      'Om locatiebepaling te gebruiken in de achtergrond en actuele notificaties te krijgen, moet u voor deze app voor "Locatie" toestemming geven om "Altijd" te gebruiken.',
    cancelButton: 'Annuleren',
    settingsButton: 'Instellingen',
    iosTitleWhenNotEnabled: 'Locatiebepaling staat uit in de achtergrond',
    iosTitleWhenOff: 'Locatiebepaling staat uit',

    // backgroundPermissionRationale settings
    androidAllowAlwaysQuestion: 'Sta toe om altijd de locatie op de achtergrond op te halen?',
    androidChangeAllTheTime: 'Verander naar altijd',

    errors: {
      0: 'Uw locatie kon niet bepaald worden',
      1: 'Watersport heeft geen permissies om uw locatie op te halen',
      2: 'Watersport kan uw locatie niet bepalen door een netwerkprobleem',
      408: 'Watersport kan uw locatie niet bepalen omdat het te lang duurt',
      499: 'Uw locatie ophalen is geannuleerd'
    }
  },

  disclaimer: {
    title: 'De Watersport App is in ontwikkeling, wees voorzichtig!',
    body:
      'Houd uw ogen op de vaarweg en houd u aan de wet. ' +
      'Het kan zijn dat routes onvolledig of incorrect zijn. ' +
      'Het is mogelijk dat data niet altijd real-time is en de precisie van de locatie niet altijd voldoende is. ' +
      'Teqplay, haar opdrachtgevers en haar dataleveranciers zijn niet verantwoordelijk voor schade als gevolg van het gebruik van deze applicatie.',
    readMore: 'Lees meer',
    dontShowAgain: 'Niet meer laten zien',
    bridgesTitle2022: 'Bruggen en sluizen 2022',
    bridges2022:
      'Bruggen en sluizen Kornwerderzand: grote drukte verwacht in recreatieseizoen 2022',
    endsupport2025: 'Per 1 april 2025 is deze app niet meer actief.',
    endsupportTitle2025: 'Informatie'
  },

  prominentDisclosure: {
    title: 'Locatiegebruik',
    approve: 'Ik ga akkoord',
    reject: 'Ik weiger',
    explanation: 'Deze app gebruikt locatiegegevens om de volgende functies in staat te stellen:',
    feature1: 'Navigeren langs een route',
    feature2: 'Berekenen van routes vanaf/naar uw locatie',
    feature3: 'Berekenen van aankomsttijden tijdens het varen',
    feature4: 'Aanvragen van brugopeningen bij uw locatie',
    feature5: 'Tonen van uw locatie op de kaart',
    background:
      'De app kan deze functionaliteiten mogelijk ook gebruiken wanneer de app zich in de achtergrond bevindt of gesloten is.',
    notProperly:
      'Bij het niet toestaan van deze permissies kan de app mogelijk niet werken zoals bedoeld.'
  },

  bridgeOpeningRequests: {
    tooFar:
      'U bent te ver weg van de brug om een openingsverzoek aan te kunnen vragen. Zodra u binnen het aanvraaggebied bent kunt u een brugopening aanvragen.',
    applyForRequest: 'Aanmeldverzoek indienen',
    errorText: 'Error',
    error: 'Er ging iets mis bij het indienen van het openingsverzoek',
    canOpen: 'Voor deze brug kan een brugopeningverzoek worden aangevraagd',
    onlyOnce: 'Dit bericht wordt maar 1 keer getoond.',
    previousRequest:
      'U heeft voor deze brug al %{amount}x eerder een brugopening aangevraagd deze sessie. Laatste keer was om %{time}.',
    downloadApp:
      'Download de Watersport app op uw mobiel of tablet om een brugverzoek in te dienen voor deze brug.',
    notHealthy:
      'De verbinding met het brugopeningaanvraagsysteem is mislukt. Brugopeningen zijn op dit moment niet aan te vragen via de app.',
    needApproval:
      'U heeft nog geen toestemming gegeven voor locatiegebruik, dit is vereist om brugopeningen aan te vragen.',
    retry: 'Sta toe',

    response: {
      BRIDGE_MAINTENANCE:
        'Er vinden onderhoudswerkzaamheden plaats aan deze brug. ' +
        'Bediening kan op dit moment niet plaatsvinden. ' +
        'Excuses voor het eventuele ongemak. ' +
        '\nVolg de seinen op de brug voor nadere instructies ' +
        'en/of kijk voor scheepvaartberichten op Twitter @ScheepvaartFRL',
      BRIDGE_ERROR:
        'Deze brug is op dit moment in storing. ' +
        'Bediening kan op dit moment niet plaatsvinden. ' +
        'Excuses voor het ongemak, wij werken aan een oplossing. ' +
        '\nVolg de seinen op de brug voor nadere instructies ' +
        'en/of kijk voor scheepvaartberichten op Twitter @ScheepvaartFRL',
      NOT_OPERATED:
        'Uw aanvraag valt buiten bedieningstijden. Kijk voor actuele bedieningstijden op www.fryslan.frl/brugbediening',
      ACCEPT: 'Bedankt voor uw aanvraag. Wij bedienen de brug zo spoedig mogelijk.',
      TECH_ERROR: 'Er is een technische fout opgetreden, probeer het later nog eens.'
    },

    responseTitle: {
      BRIDGE_MAINTENANCE: 'Onderhoudswerkzaamheden aan de brug',
      BRIDGE_ERROR: 'De brug staat in storing',
      NOT_OPERATED: 'Aanvraag buiten bedieningstijd',
      ACCEPT: 'Brugopeningaanvraag succesvol',
      TECH_ERROR: 'Error'
    },

    success: 'Brugopeningaanvraag succesvol',
    successDescription: 'Bedankt voor uw aanvraag. Wij bedienen de brug zo spoedig mogelijk.',
    viewTwitter: 'Bekijk @ScheepvaartFRL'
  },

  bridges: {
    solid: 'Niet bedienbaar',
    openable: 'Bedienbaar',
    openingRequestable: 'Brug met aanmeldfunctie app'
  }
}

export default nlLocale
