const enLocale = {
  appTitle: 'Watersport',
  version: 'version %{version}',
  save: 'Save',
  cancel: 'Cancel',
  affirm: 'Understood',
  select: 'Select',
  generalError: 'Something went wrong, please try again or contact support@teqplay.nl.',
  now: 'Now',
  yes: 'yes',
  no: 'no',
  ok: 'OK',
  latitude: 'Latitude',
  longitude: 'Longitude',

  units: {
    kmh: 'km/h',
    knots: 'knots',
    kilowatt: 'kW',
    amps: 'A'
  },

  locale: {
    selectLocale: 'Language',
    nl_NL: 'Dutch',
    en_GB: 'English',
    de_DE: 'German',
    nl_FRL: 'Frisian',

    nl_NL_native: 'Nederlands',
    en_GB_native: 'English',
    de_DE_native: 'Deutsch',
    frl_NL_native: 'Frysk'
  },

  login: {
    anonymously: 'Continue without an account',
    welcome: 'Welcome, log in to continue.',
    email: 'E-mail',
    password: 'Password',
    submit: 'Login',
    noUser: 'Not a user yet:',
    policy: 'Privacy policy',
    registerLink: 'Register an account',
    loginLink: 'Login',
    wrongCredentials: 'The username or password is invalid.',
    wrongAccountType: 'Your account type is unsuited for the Watersport app',
    forgotPassword: 'Forgot your password?',
    forgotpassemail: 'Enter your email to reset your password',
    forgotpassconfim:
      'Your request has been received, we will process this as soon as possible and contact you.',
    send: 'Send',
    newAppMessage:
      'Watersport has been updated! Accounts that have been made before October 2020 are possibly not allowed to login. Please create a new account.'
  },
  policy: {
    permission:
      'When you create an account for this Watersports app, you are granting permission for the processing of your data. We want you to understand what data we collect and how we use it.',
    storage:
      'When you sign up for a Watersports account, we store the account information you provide. This includes your vessel name, name, email address, and password. We also retain information about your location and your device data.',
    dataCollection:
      'When you use the Watersports app, we collect data to provide and improve our services. Your data is accessible to the Province of Friesland, other waterway authorities, and the app administrator, Teqplay. The purpose of using your data is to allow you, as a waterway user, to make the best possible use of the Frisian waterways. Your data is used to deliver our service and manage your user account.',
    privacyManagement:
      'You can manage your privacy in the Watersports app. You can access your data and delete your account at any time. After the termination of your account, personal data is immediately deleted. However, we retain location data for our statistics. Personal data is retained as long as you have an account.',
    dataProtection:
      "We protect your data and our applications against unauthorized use. That's why we encrypt many of our services with SSL and evaluate our approach to data collection, storage, and processing. Access to personal data is limited to employees and waterway authorities who need this data to provide our service.",
    lastUpdated:
      "Last updated: 26th October 2023 Teqplay ('us', 'we', or 'our') operates Watersport (the 'platform'). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the platform. We use your Personal Information only for providing and improving the platform. By using the platform, you agree to the collection and use of information in accordance with this policy.",
    personal:
      'Personal information: We collect some personal information that makes us able to separate one user from the other. This information is used to create your account in our systems and may include, but is not limited to your name, E-Mail address and mobile phone number',
    device:
      'Device information: We collect a specific set of device information. This information includes information about the hardware model, the version of the running operating system, unique device identifiers and the status of the wireless network.',
    account:
      'Account information: Communication is a core functionality of the platform. By sending you notifications we can let you know about important events that take place. In order to do this we need to know the Google account identifier that belongs to the Google account you use on your phone.',
    thirdParties: 'Third parties:',
    ageRestriction:
      'Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s why we do not knowingly collect personal information from anyone under 13.',
    cookies:
      "Like many websites and platforms, we use 'cookies' to collect information. We use these cookies to prevent you from having to log in each time you use the platform, for example.",
    security:
      'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.',
    changesToPrivacyPolicy:
      'we reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the platform after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.',
    contactUs:
      'If you have any questions about this Privacy Policy, please contact us. For our contact details:',
    title: {
      children: 'Children',
      cookies: 'Cookies',
      security: 'Security',
      contactUs: 'Contact us',
      changes: 'Changes to this Privacy Policy',
      watersport: 'Watersport Privacy Policy'
    },
    back: 'Back'
  },
  informationSharing: {
    intro:
      'Information sharing We can share the information that you provide under the following circumstances: Third parties:',
    legalReasons:
      'For legal reasons: We may share information about you if we reasonably believe that disclosing the information is needed to:',
    legalReasonsList: {
      complyWithLegalProcess:
        'comply with any valid legal process, governmental request, or applicable law, rule, or regulation.',
      investigateViolations:
        'investigate, remedy, or enforce potential Terms of Service violations.',
      protectRights: 'protect the rights, property, and safety of us, our users, or others.',
      detectFraud: 'detect and resolve any fraud or security concerns.'
    }
  },

  anonymous: {
    notAllowed:
      'To perform this action you must be logged in. Click on "Create an account" to create an account for free',
    createAccount: 'Create an account',
    cancel: 'Not now'
  },

  shipTypes: {
    select: 'Select ship type',
    MOTOR_YACHT: 'Motor yacht',
    SPEEDBOAT: 'Speedboat',
    SAILING: 'Sailboat',
    SAILING_WITH_MOTOR: 'Sailboat with engine',
    SPORT_FISHERMEN_VESSEL: 'Sport fishermen vessel',
    SAILING_MOTOR_BIG: 'Sail or motor boat bigger than 20m',
    OTHER_RECREATIONAL_VESSEL: 'Other recreational vessel'
  },

  register: {
    createAccount: 'Register an account',
    submit: 'Register',
    done: 'Success!',
    backToLogin: 'Back to login',
    emailAddress: 'E-mail',
    password: 'Password',
    repeatPassword: 'Repeat password',
    phoneNumber: 'Phone number',
    shipName: 'Ship name',
    shipType: 'Ship type',
    length: 'Ship length (m)',
    width: 'Ship width (m)',
    height: 'Ship height (m)',
    draught: 'Ship draught (m)',
    dimensionsHead: 'Ship dimensions',
    pleaseFillRealistic: 'The ship dimensions should be between 0 and 100m',
    passwordsNotMatch: 'The passwords do not match',
    fieldsRequired: 'Fill all required fields (*)',
    alreadyExists: 'There is already an account for this e-mail address',
    emailInvalid: 'Your filled in e-mail address is invalid',
    failedToFetch: 'Could not connect with the server. Check your internet connection.',
    serverError: 'An unknown error has occurred, please try again or contect support@teqplay.nl',
    gainedAccessToVarenFriesland:
      'Your already existing Teqplay account now has access to Watersport. Login using your existing information or request a new password through the Forgot Password button with the same e-mail address.',

    gdpr: {
      title: 'Privacy policy',
      privacyBody:
        'If you create an account for this Watersport-app, you are thereby agreeing to allowing your data to be processed. We want that you understand which data we collect and how we use it.' +
        '<br /><br />When you register for a Watersport-account, we store the account data that you submit. This is your ship name, name, e-mailaddress and password. We also keep information about your location and your device.' +
        '<br /><br />When you use the Watersport-app, we collect data to provide and improve our services.' +
        '<br /><br />Your data is available to the Provincie Fryslân, for other waterway managers and the app manager Teqplay. The goal of the usage of your data is to provide you as waterway user with the most optimal experience of the Frisian waterways. Your data is being used to provide our service and manage your user account.' +
        '<br /><br />You can manage your privacy settings inside the Watersport app. You can view your own data and delete your account at any point. After closing your account your personal data will be deleted immediately. We do keep anonimised location data for statistical purposes. Personal data will be kept as long as you have an account.' +
        '<br /><br />We protect you and our data and apps against unauthorized usage. That is why we encode a lot of our services with SSL and we continously evaluate our way of working considering collecting, storing and processing your data. We limit the access to personal data to company employees and waterway managers who need this data in our to provide their services.' +
        '<br /><br />For questions about this disclaimer you can contact: provincie@fryslan.frl',
      accept: 'Accept',
      deny: 'Deny',
      error: 'You must accept the privacy policy in order to use the Watersport app.',
      view: 'Read policy',
      close: 'Close'
    }
  },

  routeSelection: {
    routeNumbered: 'Route %{routeNumber}',
    selectRoutePlaceholder: 'Select your voyage',
    startingPoint: 'Departure',
    destinationPoint: 'Destination',
    selectStartingPoint: 'Select your departure',
    selectDestination: 'Select your destination',
    selectViaPoint: 'Select a via point',
    viaPoint: 'Via point',
    options: 'Options',
    calculateRoute: 'Calculate route',
    arrival: 'Arrival',
    departure: 'Departure',
    searchOnLocation: 'Search by location',
    noOptions: 'No locations found',
    searchAdvice: 'Search by location',
    currentLocation: 'Current location',
    selectOnMap: 'Select location on the map',
    distance: 'Distance',
    arrivalTime: 'Arrival time',
    viewList: 'List view',
    startRoute: 'Start route',
    deleteRoute: 'Delete route',
    saveRoute: 'Save',
    cancel: 'Cancel',
    list: 'Objects',
    details: 'Info',
    map: 'Map',
    notAllowedDimensions: 'This route is not traversable using your provided ship dimensions',
    notAllowedBridge: 'Not all bridges can be passed using your provided ship dimensions',
    noRoutesFoundWarning: 'No routes have been found for this route',
    noDetailsFoundWarning: 'No details for this object available',
    routeDetails: 'Route information',
    routeStoredSucces:
      'The route has been saved. When selecting the locations for your route you will see your saved routes.',
    showSavedRoutes: 'Show saved routes',
    hideSavedRoutes: 'Hide saved routes',
    fromTo: '%{from} to %{to}',
    dateSaved: 'Saved on',
    saveRouteTitle: 'Saving a route',
    noNameFilled: 'Fill in a name to save the route',
    routeNameInput: 'Name of route',
    selectionWarning: 'Select a departure and destination point',
    pauseRoute: 'Pause route',
    resumeRoute: 'Continue route',
    noLocationWarning: 'Turn on your GPS to start the route',
    shipDepth: 'Shallowest point',
    shipHeight: 'Lowest vertical clearance',
    maxWidth: 'Narrowest point',
    waitingTime: 'Expected waiting time',
    duration: 'Total duration',
    createGeolocation: '"%{value}"',
    node: '%{value} (Node)'
  },

  routeList: {
    itemDetails: {
      opening_required:
        'A bridge opening is required for your ship. Request the bridge opening yourself.',
      opening_required_bms_bridge:
        'A bridge opening is required for your ship. This will be requested automatically.',
      cannot_pass_bridge: 'Look out! Your ship does not fit underneath this bridge.',
      opening_requested: 'A bridge opening was requested',
      opening_planned: 'The bridge will open soon',
      communication: 'Communication',
      vhf: 'VHF',
      control_times: 'Operating times',
      yes: 'Yes',
      no: 'No',
      NAP: 'NAP',
      opening: 'Opening',
      type: 'Type',
      opening_width: 'Width',
      width: 'Width',
      length: 'Length',
      closed_height: 'Closed height',
      opened_height: 'Opened height',
      telephone: 'Tel',
      lock_chamber: 'Lock chamber',
      max_width: 'Max. width (m)',
      threshold_outside: 'Threshold outside',
      threshold_inside: 'Threshold inside',
      address: 'Shore address',
      from: 'From',
      to: 'to',
      wholeDay: 'Whole day',
      bridge_type: {
        VST: 'Fixed bridge',
        DR: 'Swing bridge',
        BC: 'Bascule bridge',
        OPH: 'Drawbridge',
        HEF: 'Lift bridge',
        DBC: 'DBC'
      },
      bridge_status: {
        open: 'This bridge is opened at this moment.',
        closed: 'This bridge is closed at this moment.',
        unknown: 'The current status of this bridge is unknown.'
      },
      bridge_operating_rules: {
        today: 'Today',
        tomorrow: 'Tomorrow',
        holiday: 'Holidays',
        noService: 'No service'
      },
      bridgeRecommendation: {
        AFSC: 'Descend to lower waters',
        AFVT: 'Downstream',
        BERP: 'Exclusively for commercial shipping',
        BSCH: 'Guided shipping',
        BVAF: 'Exclusively for commercial shipping on call',
        ETAA: 'End time depending supply',
        FACO: 'Optional opening',
        GEEN: 'No service',
        HAR: 'Direction Haringvliet only',
        KAN: 'Direction towards channel only',
        KONV: 'Convoy',
        OPEN: 'Is (generally) open',
        OPSC: 'Ascend to higher waters',
        OPVT: 'Upstream',
        RECR: 'Recreational sailors',
        STDU: 'Going out of town only',
        VERZ: 'On call only',
        ZELF: 'Self service',
        ZUID: 'Southbound only'
      },
      no_items_found: 'No details for this object are available',
      waitingTime: '%{waitingTime} waiting time expected for the next %{objectType}',
      note: 'Note',
      bridgeOpeningNote: 'Note about bridge opening',
      chamberNote: 'Note about lock chamber',
      waterLevel: 'Water level',
      operable: 'Operable',
      phoneNumber: 'Phone number',
      moreInfo: 'More information'
    },
    objectTypes: {
      BRIDGE: 'bridge',
      LOCK: 'lock',
      START: 'start',
      END: 'end',
      NOTIFICATION: 'notification',
      WATERMETER: 'watermeter',
      TRAFFIC_SIGN: 'traffic sign',
      BOTTLENECK: 'bottleneck',
      VHFSECTOR: 'VHF sector',
      UNKNOWN: 'unknown object'
    }
  },

  navigationPage: {
    destination: 'Destination',
    arrival: 'Arrival',
    next_object: 'Next object',
    no_user_location_warning: 'No GPS location found',
    no_notifications: 'There are no notifications for the current sailing location',
    list_view: 'List',
    notifications: 'Notifications',
    ship_speed: 'Speed',
    route_paused_warning: 'Route paused',
    stop_route_confirmation: 'Are you sure you want to stop the route?',
    pause_route: 'Pause route',
    stop_route: 'Stop route',
    resume_route: 'Resume route'
  },

  modalSelectPreviousRoute: {
    question: 'Do you want to continue with your previous route?',
    yes: 'yes',
    no: 'no'
  },

  pages: {
    welcome: 'Welcome',
    feedback: 'Feedback',
    navigation: 'Navigation',
    myProfile: 'My profile',
    logout: 'Logout',
    loginLink: 'Login',
    register: 'Create account',
    policy: 'Privacy Policy'
  },

  map: {
    followButton: 'Follow ship',
    speedButton: 'Set up speed',
    activelayers: 'Select map layers',

    category: {
      categoryBerths: 'Berths',
      categoryFairway: 'Fairways',
      categoryElements: 'Elements',
      maptype: 'Map'
    },

    crossings: 'VDJS crossings',
    public_berths: 'Public berths',
    berthsOutside: 'Berths outside',
    fairways: 'Fairways',
    CEMTClass: 'CEMT class',
    fairwayDepth: 'Depth',
    recreationalClass: 'Recreational class',
    electricRoutes: 'Electric routes',
    contractDepth: 'Contract depth',
    levelDepth: 'Level depth',
    inlandHarbours: 'Inland harbours',
    chargingStations: 'Charging stations',
    bridges: 'Bridges',
    kilometre: 'Kilometer signs',
    trailerSlope: 'Trailer slopes',
    wasteWaterStations: 'Waste water stations',
    nodes: 'Nodes',
    winterRestAreas: 'Bird resting areas',
    AISShips: 'Ships',
    fastRoutes: 'Fast sailing areas',
    fastRoutesNoInfo: 'No information available',

    speed: {
      title: 'Set up the speed for calculating arrival times',
      useShip: 'Use ship speed',
      useCustom: 'Set up your own speed',
      setRealisticSpeed: 'Provide a speed between 1 and 50 km/h',
      saved: 'Your change has been saved.'
    },

    details: 'details',

    notification: {
      routeNotifications: 'Route notifications',
      tooMuchBerths: 'Too many berths found, zoom in further to show berths.',
      approachVdjsHeader: 'VDJS crossing',
      approachingVdjsCrossing:
        'You are approaching the crossing %{crossingName}. ' +
        'For this crossing there are routes available from Varen Doe Je Samen.',
      approachingVdjsCrossingShort: 'You are approaching the crossing %{crossingName}',
      show: 'Show on map',
      channelNotificationHeader: 'Busy waterway',
      nearingChannelNotification:
        'You are approaching the %{channelName}. Look out with sailing, there is a lot of professional shipping activities here.',
      zoomInNotification: 'Zoom in to see the following map layers:'
    }
  },

  myProfile: {
    title: 'My account',
    titleProfile: 'Account information',
    titlePasswordChange: 'Change password',
    titleShipDimensions: 'Ship dimensions',
    myShip: 'My ship',
    password: 'Password',
    email: 'E-mail address',
    mobile: 'Mobile phone',
    mmsi: 'MMSI',
    currentPassword: 'Current password',
    newPassword: 'New password',
    repeatNewPassword: 'Repeat new password',
    save: 'Save',
    newPasswordNoMatch: 'The new passwords do not match',
    updatedPasswordSuccess: 'Your password is saved',
    wrongPassword: 'Wrong current password',
    tooWeak: 'Your new password is too weak',
    titleDeleteAccount: 'Delete account',
    selectAShip: 'Select a ship',
    updateProfileSuccess: 'Account has been updated',
    updateProfileFailed:
      'Something went wrong while updating your account, please try again later or contact support@teqplay.nl',
    areYouSureDeleteAccount: 'Are you sure you want to delete your account? This cannot be undone.',
    removeSuccess: 'Account has been deleted',
    no: 'No',
    yes: 'Yes',
    yesDelete: 'Yes, delete',
    dimensionsErrorMessage: '* Dimensions have to be between 0 and 100 meters.',
    dimensionsWarningMessage: '* Fill in a value.',
    dimensionsSaveWarning: 'The dimensions for your ship are not filled in correctly.',
    ship: {
      length: 'Length (m)',
      width: 'Width (m)',
      height: 'Height (m)',
      draught: 'Draught (m)'
    },

    passwordTips: {
      title: 'Password requirements',
      match: 'Passwords match',
      noMatch: 'Passwords do not match',

      requirements: 'Password requirements',
      requirementsInfo:
        'We want that your password is as safe as possible to protect you and your data. This is why we have the following requirements for your password.',

      tips: 'Tips for a secure password',
      tipsInfo:
        'You may choose whatever content of your password, but we have some tips for you to make it even more secure.',
      tipsCasing: 'Password contains upper and lowercase characters',
      tipsNumber: 'Password contains a number',
      tipsSpecialCharacters: 'Password contains a special character like !@#$%',
      tipsLength: 'Password is 8 characters or longer',
      tipsUnique: 'Use a unique password that you do not use for everything',
      tipsUniqueSubtitle:
        'This obviously is not required and we can not even know or check this since your password is encrypted. But for the highest security standards we ask you to create a unique password.',
      tipsNoPersonalInfo: 'Do not use easily guessable personal information inside your password',
      tipsStoreSafely: 'Store your passwords securely',
      tipsStoreSafelySubtitle:
        'We recommend the usage of a password manager. You are also able to write the password down and store it in a safe place.',

      allMet: 'Now that is a secure password, I am proud of you! 👍',
      abideToAll: 'Your new password does not meet the requirements'
    }
  },

  feedbackPage: {
    title: 'Give feedback',
    description: 'Description',
    descriptionText: 'Add a description of your feedback',
    location: 'Location',
    locationText: 'Select a place on the map where your feedback or remark is about.',
    photo: 'Image',
    photoText: 'Add an image about the mentioned location',
    uploadText: 'Drag or click to add an image',
    sendFeedback: 'Send feedback',
    warningMessage: 'Add a description of your feedback',
    successMessage: 'Your feedback has been sent, thank you for your contribution!',
    errorMessage: 'Something went wrong with sending your feedback, sorry for the inconvenience.',
    done: 'Done',
    chooseLocation: 'Click here to add a location on the map',
    noLocationKnown: 'No location known',
    noEmergencies: 'This function is not for emergencies, always call 112 in case of an emergency.'
  },

  mobile: {
    navigation_active: 'Navigation active',
    navigation_active_destination: 'Navigation to %{destination} active',
    offline_title: 'No connection',
    offline_text: 'There is no connection, your route shall be paused.',
    high_speed_title: 'You are going too fast',
    high_speed_text:
      'Are you still sailing? You are going quicker than 80 km/h, please stop the route when you are not sailing in the app.'
  },

  mapLayers: {
    last_updated: 'Last update',
    past: 'ago',
    berthLayer: {
      width: 'Width',
      length: 'Length',
      draught: 'Draught',
      berthName: 'Name of berth',
      owner: 'Owner',
      dangerousGoodsLevel: 'Dangerous goods level',
      mooringOptions: 'Mooring options',
      mooringServices: 'Mooring services',
      mooringFunctions: 'Mooring functions',
      mooringHourTimeLimit: 'Mooring time limit',
      shoreConnection: 'Shore connection',
      sort: 'Type',
      viewFotoURL: 'View image of berth'
    },

    chargingStations: {
      city: 'City',
      chargingPointAvailable: 'Charging point available',
      publicAvailable: 'Public charging point',
      greenPower: 'Green power',
      paymentMethod: 'Payment method',
      paymentMethods: {
        CASH: 'Cash',
        CARD: 'Card',
        CASH_AND_CARD: 'Cash and card',
        FREE: 'Free',
        UNKNOWN: 'Unknown'
      },
      comments: 'Comments',
      chargingPossibilities: 'Charging possibilities',
      unavailable: 'Unavailable',
      connections: 'connections'
    },

    inlandHarbours: {
      city: 'City',
      shortStayPlaces: 'Short stay places',
      longStayPlaces: 'Long stay places',
      suppliesFuel: 'Fuel available'
    },

    wasteWaterStations: {
      city: 'City',
      address: 'Address',
      containsWasteWater: 'Contains wastewater',
      bilge: 'Bilge',
      phone: 'Phone number'
    }
  },

  pushNotification: {
    buttonClose: 'OK'
  },

  geolocationStrings: {
    warning: 'Warning',
    info: 'Information',
    deniedPermission: 'The app has no permission to use your location.',
    clickToRetry: 'Click to try again',
    stillDenied:
      'App still has no permission, go to Settings > Apps > Watersport > Permissions and turn on Location services.',
    clickToMinimize: 'Click to minimize',

    androidWhenActive: 'Location services active',
    androidChannelName: 'GPS location services active',
    androidPermissionDeniedInfo: 'Without location services the app has limited functionality',

    whenInUse:
      'To use location services in the background and retrieve real time notifications, you must turn on location services to "Always".',
    instructions:
      'To use location services in the background and retrieve real time notifications, you must turn on location services to "Always".',
    cancelButton: 'Cancel',
    settingsButton: 'Settings',
    iosTitleWhenNotEnabled: 'Location services are off in the background',
    iosTitleWhenOff: 'Location services are off',

    // backgroundPermissionRationale settings
    androidAllowAlwaysQuestion: "Allow access to this device's location in the background?",
    androidChangeAllTheTime: 'Change to Allow all the time',

    errors: {
      0: 'Location is unknown',
      1: 'Location cannot be fetched due to permission issues with the app',
      2: 'Location cannot be fetched due to a network error',
      408: 'Location cannot be determined because it took too long',
      499: 'Location determination has been cancelled'
    }
  },

  disclaimer: {
    title: 'The Watersport App is still in development, please be careful!',
    body:
      'Keep your eyes on the waterway and obey the law. There is a possibility that routes provided ' +
      'are incomplete or incorrect. It is possible that the data is not always real-time' +
      'and the precision of the location is not always sufficient. Teqplay, its clients and its data suppliers' +
      'are not responsible for any damages that are as a result of using this app.',
    readMore: 'Read more',
    dontShowAgain: "Don't show this again",
    bridgesTitle2022: 'Bridges and locks 2022',
    bridges2022:
      'Bridges and locks Kornwerderzand: expected congestion for 2022 recreational season',
    endsupport2025: 'This app will no longer be active as of April 1, 2025.',
    endsupportTitle2025: 'Information'
  },
  prominentDisclosure: {
    title: 'Location usage',
    approve: 'I approve',
    reject: 'I reject',
    explanation: 'This app uses location data to facilitate the following features:',
    feature1: 'Navigating along a route',
    feature2: 'Calculating routes from/to your location',
    feature3: 'Calculating arrival times to your destination',
    feature4: 'Requesting bridge openings near your location',
    feature5: 'Showing your location on a map',
    background:
      'The app could possibly also use these features when it is closed or in the background',
    notProperly: 'By rejecting these permissions, the app might not work as intended.'
  },

  bridgeOpeningRequests: {
    UNAVAILABLE: 'Requesting bridge openings is not available at the moment.',
    willOpen: 'Bridge will open',
    requested: 'Opening request submitted',
    noRequestsActive: 'No opening requests active',
    tooFar: 'You are too far away from the bridge to request an opening.',
    status: 'Status',
    applyForRequest: 'Submit opening request',
    error: 'Something went wrong while submitting your opening request',
    errorStatus: 'Something went wrong while retrieving the bridge status.',
    errorNoStatusAvailable: 'No status available for this bridge.',
    errorText: 'Error',
    canOpen: 'A bridge opening can be requested for this bridge.',
    onlyOnce: 'This message will only be shown once.',
    previousRequest:
      'You have requested a bridge opening for this bridge %{amount}x before this session. Last time was at %{time}.',
    downloadApp:
      'Download the Watersport app on your phone or tablet to request a bridge opening for this bridge.',
    notHealthy:
      'The connection to the bridge opening request system has failed. Bridge openings cannot be requested via the app at the moment.',
    needApproval:
      'You have not given any permission for location usage, this is required to request a bridge opening',
    retry: 'Allow',

    response: {
      BRIDGE_MAINTENANCE:
        'This bridge is under maintenance. ' +
        'Bridge openings cannot take place at this time. We apologize for any inconvenience. ' +
        '\nFollow the signals on the bridge for further instructions and / or look for ship messages on Twitter @ScheepvaartFRL',
      BRIDGE_ERROR:
        'This bridge is currently in malfunction. ' +
        'Bridge openings cannot take place at this time. ' +
        'Sorry for the inconvenience, we are working on a solution. ' +
        '\nFollow the signals on the bridge for further instructions and / or look for ship messages on Twitter @ScheepvaartFRL',
      NOT_OPERATED:
        'Your request is outside operating hours. Look for current operating times at www.fryslan.frl/brugbediening ',
      ACCEPT: 'Thank you for your request. We operate the bridge as soon as possible. ',
      TECH_ERROR: 'A technical error has occurred, please try again later. '
    },

    responseTitle: {
      BRIDGE_MAINTENANCE: 'Bridge is under maintenance',
      BRIDGE_ERROR: 'Bridge is currently in malfunction',
      NOT_OPERATED: 'Request is outside operating hours',
      ACCEPT: 'Bridge opening request successful',
      TECH_ERROR: 'Error'
    },

    success: 'Bridge opening request successful',
    successDescription:
      'Thank you for your request. We will operate the bridge as soon as possible.',
    viewTwitter: 'View @ScheepvaartFRL'
  },

  bridges: {
    solid: 'Not operable',
    openable: 'Operable',
    openingRequestable: 'Bridge with registration function in app'
  }
}

export default enLocale
