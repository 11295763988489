import React, { useState } from 'react'
import Modal from 'react-modal'
import { I18n } from 'react-redux-i18n'
import { useLocalStorage } from '../../customHooks/useLocalStorage'
import './EndSupportPopup.scss'

const EndSupportPopup: React.FunctionComponent = () => {
  const [showPopup, setShowPopup] = useState<boolean>(true)
  const [customToggle, setCustomToggle] = useState<boolean>(false)
  const [customMessage, setCustomMessage] = useLocalStorage<boolean>(
    'WATERSPORT-endsupport2025',
    false
  )

  if (customMessage) {
    return null
  }

  return (
    <Modal className="modal disclaimer endsupport-modal" isOpen={showPopup}>
      <div className="disclaimer-wrapper">
        <h2>{I18n.t('disclaimer.endsupportTitle2025')}</h2>
        <p>{I18n.t('disclaimer.endsupport2025')}</p>
        <a href="https://www.fryslan.frl/watersport" target="_blank" rel="noreferrer">
          {I18n.t('disclaimer.readMore')}
        </a>
        <div className="buttons">
          <div className="reminder">
            <label>
              <input
                type="checkbox"
                onChange={() => setCustomToggle(!customToggle)}
                checked={customToggle}
              />
              {I18n.t('disclaimer.dontShowAgain')}
            </label>
          </div>
          <button
            className="button"
            onClick={() => {
              setShowPopup(false)
              setCustomMessage(customToggle)
            }}
          >
            {I18n.t('affirm')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EndSupportPopup
