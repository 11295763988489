import * as Sentry from '@sentry/browser'
import pjson from '../../package.json'
import { getEnvironment, isCordovaApp } from './cordovaUtils'

const ERROR_EXCEPTIONS: string[] = [
  'Unexpected EOF',
  'The request timed out',
  'The network connection was lost',
  'A server with the specified hostname could not be found.',
  'Unable to parse JWT token. Authorization failed: No logged in user found.',
  'Token expired for user',
  'Permission denied',
  "Cannot read property '_leaflet_pos' of undefined",
  'The Internet connection appears to be offline.',
  'No route found for current logged in user',
  'The operation couldn’t be completed. Software caused connection abort',
  'An SSL error has occurred and a secure connection to the server cannot be made.',
  'Could not connect to the server.',
  'Failed to fetch',
  'Network request failed',
  'anonymous.notAllowed',
  'Load failed',
  'Fetch is aborted'
]

// Start sentry when not developing
export function initializeSentry() {
  const environment = getEnvironment()

  if (environment !== 'localhost' || isCordovaApp) {
    Sentry.init({
      dsn: 'https://2562388a1cf741fcb12050818d351784@o126205.ingest.sentry.io/1388016',
      release: `watersport@${pjson.version}`,
      environment,
      attachStacktrace: true,
      beforeSend: (event, hint) => {
        // filter out UnhandledRejection errors that have no information
        if (
          event !== undefined &&
          event.exception !== undefined &&
          event.exception.values !== undefined &&
          event.exception.values.length === 1
        ) {
          var error = event.exception.values[0]
          if (
            error.type === 'UnhandledRejection' &&
            error.value === 'Non-Error promise rejection captured with value: '
          ) {
            return null
          }
        }
        let doNotSend = false
        ERROR_EXCEPTIONS.forEach(e => {
          if (
            // Explicit casting as any, since otherwise TypeScript would throw errors...
            (hint?.originalException as any)?.toString().includes(e) ||
            // Filter out any unrelevant errors from backgroundGeolocation plugin
            // See LocationError in https://transistorsoft.github.io/cordova-background-geolocation-lt/index.html#locationerror
            (hint?.originalException as any)?.toString() === '0' || // Location unknown
            (hint?.originalException as any)?.toString() === '1' || // Location permission denied
            (hint?.originalException as any)?.toString() === '2' || // Network error
            (hint?.originalException as any)?.toString() === '408' || // Location timeout
            (hint?.originalException as any)?.toString() === '499' // Location request cancelled
          ) {
            doNotSend = true
          }
        })

        if (!doNotSend) {
          console.error(`[SENTRY] Sent event`, hint?.originalException)
          return event
        } else {
          console.warn(`[SENTRY] Filtered event`, hint?.originalException)
          return null
        }
      }
    })
  }
}

export function sendExceptionToSentry(appError?: any, restError?: any, errorInfo?: any) {
  // Don't send 401 to sentry
  console.log('sendExceptionToSentry init', { appError, restError, errorInfo })
  if ((restError && restError.status === 401) || (appError && appError.status === 401)) {
    return
  }

  const error = appError ? appError : restError
  if (!error) {
    console.warn(
      `Trying to send exception but there is no error!`,
      JSON.stringify({ appError, restError, errorInfo })
    )
    return
  }

  // Send to sentry
  Sentry.withScope(scope => {
    if (errorInfo) {
      Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]))
      scope.setExtra('source', 'sendExceptionToSentry')
    }

    console.error(`[SENTRY] sendExceptionToSentry`, error, errorInfo)
    if (error.message) {
      Sentry.captureException(error.message)
    } else {
      Sentry.captureException(error)
    }
  })
}

export function sendMessageToSentry(message: string, messageInfo?: any) {
  console.warn('[SENTRY] sendMessageToSentry', message, messageInfo)
  Sentry.withScope(scope => {
    if (messageInfo) {
      Object.keys(messageInfo).forEach(key => scope.setExtra(key, messageInfo[key]))
      scope.setExtra('source', 'sendMessageToSentry')
    }

    Sentry.captureMessage(message)
  })
}
