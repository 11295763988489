import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import * as MapboxGl from 'mapbox-gl'
import circle from '@turf/circle'
import { Layer, Feature, Popup, GeoJSONLayer } from 'react-mapbox-gl'
import { insideCircle } from 'geolocation-utils'

import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'

import {
  IFullPosition,
  ILocationPermissions
} from '../../geoLocationWatcher/GeoLocationWatcherTypes'
import {
  IBridgeDetails,
  IBridgeOpeningInfo,
  IRouteItemBridge,
  IWatchDogStatus,
  ISelectedRoute,
  IRouteItem
} from '../../../services/TeqplayApiService/TeqplayApi'
import { determineOperatingRulesForDaySet } from '../../../utils/openingHours'
import { parseStringForID, keysrt } from '../../../utils/general'
import { formatDateTimeShort } from '../../../utils/dates'
import { IRootProps } from '../../../@types/types'

import BridgeDrawing from '../../itemDetails/bridgeDrawing/BridgeDrawing'
import bridgesOperableIcon from '../icons/bridges_operable.svg'
import bridgesOperableOpenIcon from '../icons/bridges_operable_open.svg'
import bridgesNotOperableIcon from '../icons/bridges_not_operable.svg'
import bridgesNotPassableIcon from '../icons/bridges_not_passable.svg'
import bridgesOperableApp from '../icons/bridges_operable_app.svg'
import BridgeOpeningRequests from '../../bridgeOpeningRequests/BridgeOpeningRequests'

import './layerPopup.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
  currentUserLocation?: IFullPosition | null
  watchdogStatus?: IWatchDogStatus
  locationPermissions: ILocationPermissions
  bridgeOpenings?: IBridgeOpeningInfo[]
  routeBridges?: IRouteItemBridge[]
  bounds?: MapboxGl.LngLatBounds
  cursor?: HTMLCanvasElement
  navigationRoute?: ISelectedRoute
  selectedRouteItem?: IRouteItem | null
  setSelectedRouteItem?: (item: IRouteItem) => void
}

interface IBridgeRadius {
  defaultItems: Bridges[]
  category: string
  currentUserLocation?: IFullPosition | null
}

interface IBridgeMarker {
  defaultItems: Bridges[]
  category: string
  iconUrl: string
  cursor?: HTMLCanvasElement
  setSingleItem: (item: any) => void
}

type Bridges = IRouteItemBridge | IBridgeDetails

const BridgeLayer = ({
  teqplayApiService,
  bridgeOpenings,
  routeBridges,
  selectedRouteItem,
  setSelectedRouteItem,
  bounds,
  cursor,
  currentUserLocation,
  locationPermissions,
  watchdogStatus,
  navigationRoute
}: IProps) => {
  const [bridges, setBridges] = useState<IBridgeDetails[]>([])
  const [singleItem, setSingleItem] = useState<IBridgeDetails | null>(null)
  const locale = useSelector((r: IRootProps) => r.i18n.locale)
  const { dateBasedRules, activeOperatingPeriods } = determineOperatingRulesForDaySet(
    singleItem?.operatingTimes,
    locale
  )
  const notes = [singleItem?.note, singleItem?.operatingTimes?.note]
    .concat(activeOperatingPeriods.map(x => x.note))
    .filter(x => x)
    .filter((v, i, s) => s.indexOf(v) === i)

  useEffect(() => {
    if (!routeBridges) {
      fetchItems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds])

  useEffect(() => {
    if (selectedRouteItem) {
      setSingleItem(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRouteItem])

  const bridgesByAppId = bridgeOpenings?.map(obj => obj.isrsId)
  const openableByApp = routeBridges?.filter(item => bridgesByAppId?.includes(item.refUuid))
  const bridgesNotOperable = routeBridges?.filter(item => !item.canOpen)
  const bridgesNotPassable = routeBridges?.filter(item => item.canBePassed === false)
  const bridgesOperable = routeBridges?.filter(item => item.openingRequired)

  return (
    <>
      <BridgeMarker
        iconUrl={bridgesOperable ? bridgesOperableOpenIcon : bridgesOperableIcon}
        cursor={cursor}
        defaultItems={
          bridgesOperable ||
          bridges.filter(
            item => item.canOpen && !bridgeOpenings?.some(o2 => item.isrsCode === o2.isrsId)
          )
        }
        category={`bridgesOperable ${routeBridges ? 'custom' : ''}`}
        setSingleItem={routeBridges && setSelectedRouteItem ? setSelectedRouteItem : setSingleItem}
      />
      <BridgeMarker
        iconUrl={bridgesNotOperableIcon}
        cursor={cursor}
        defaultItems={
          bridgesNotOperable?.filter(o1 => !bridgeOpenings?.some(o2 => o1.refUuid === o2.isrsId)) ||
          bridges.filter(item => !item.canOpen)
        }
        category={`bridgesNotOperable ${routeBridges ? 'custom' : ''}`}
        setSingleItem={routeBridges && setSelectedRouteItem ? setSelectedRouteItem : setSingleItem}
      />
      <BridgeMarker
        iconUrl={bridgesOperableApp}
        cursor={cursor}
        defaultItems={
          openableByApp ||
          bridges.filter(o1 => bridgeOpenings?.some(o2 => o1.isrsCode === o2.isrsId))
        }
        category={`bridgesOperableApp ${routeBridges ? 'custom' : ''}`}
        setSingleItem={routeBridges && setSelectedRouteItem ? setSelectedRouteItem : setSingleItem}
      />
      {routeBridges && setSelectedRouteItem && (
        <BridgeMarker
          iconUrl={bridgesNotPassableIcon}
          cursor={cursor}
          defaultItems={bridgesNotPassable || []}
          category="bridgesNotPassable"
          setSingleItem={setSelectedRouteItem}
        />
      )}
      <BridgeRadius
        defaultItems={
          openableByApp ||
          bridges.filter(o1 => bridgeOpenings?.some(o2 => o1.isrsCode === o2.isrsId))
        }
        category={`bridgeRadius ${routeBridges ? 'custom' : ''}`}
        currentUserLocation={currentUserLocation}
      />

      {!routeBridges &&
        singleItem &&
        singleItem.isrsCode !== selectedRouteItem?.refUuid &&
        singleItem.location.coordinates && (
          <Popup coordinates={singleItem.location.coordinates}>
            <div className="popup-bridge">
              <span className="close icon-cancel-circled" onClick={() => setSingleItem(null)} />
              <div className="bridge-title">
                <img
                  src={singleItem.canOpen ? bridgesOperableIcon : bridgesNotOperableIcon}
                  alt="bridgeIcon"
                />
                <h2>{singleItem.name}</h2>
              </div>
              {watchdogStatus && (
                <BridgeOpeningRequests
                  bridgeRouteItem={singleItem}
                  bridgeOpeningInfo={bridgeOpenings?.find(
                    bridge => bridge.isrsId === singleItem?.isrsCode
                  )}
                  teqplayApiService={teqplayApiService}
                  currentLocation={currentUserLocation || null}
                  watchdogStatus={watchdogStatus}
                  isInsideRouteItem={insideCircle(
                    currentUserLocation && currentUserLocation.location
                      ? [
                          currentUserLocation?.location?.latitude,
                          currentUserLocation?.location?.longitude
                        ]
                      : [0, 0],
                    singleItem.location?.coordinates
                      ? {
                          latitude: singleItem.location?.coordinates[0],
                          longitude: singleItem.location?.coordinates[1]
                        }
                      : [0, 0],
                    250 || 0
                  )}
                  locationPermissions={locationPermissions}
                />
              )}
              <div className="item-info-details">
                <section className="details-section">
                  {singleItem.name && (
                    <b>
                      {singleItem.name}
                      {singleItem.city &&
                        singleItem.name !== singleItem.city &&
                        `, ${singleItem.city}`}
                    </b>
                  )}
                  {singleItem.fairwayName && (
                    <div>
                      <b>{singleItem.fairwayName}</b>
                    </div>
                  )}
                </section>

                {(singleItem.vhfChannel || singleItem.phoneNumber) && (
                  <section className="details-section">
                    {singleItem.vhfChannel && (
                      <div className="route-item-details-item">
                        <b>{I18n.t('routeList.singleItem.vhf')}:</b> {singleItem.vhfChannel}
                      </div>
                    )}
                    {singleItem.phoneNumber && (
                      <div className="route-item-details-item">
                        <b>{I18n.t('routeList.singleItem.phoneNumber')}: </b>
                        {singleItem.phoneNumber}
                        <a href={'tel:' + singleItem.phoneNumber}>
                          <i className="icon-phone" />
                        </a>
                      </div>
                    )}
                  </section>
                )}

                {(singleItem.canOpen || singleItem.waterLevel || singleItem.bridgeOpening) && (
                  <section className="details-section">
                    {singleItem.canOpen !== undefined && (
                      <div>
                        <b>{I18n.t('routeList.singleItem.operable')}</b>:{' '}
                        {singleItem.canOpen
                          ? I18n.t('routeList.singleItem.yes')
                          : I18n.t('routeList.singleItem.no')}
                      </div>
                    )}

                    {dateBasedRules.length > 0 && (
                      <div className="opening-item" id="bridge-details-opening-hours">
                        <div className="route-item-caption">
                          <b>{I18n.t('routeList.singleItem.control_times')}:</b>
                        </div>
                        {dateBasedRules.map((time, key) => (
                          <div
                            key={key}
                            className="opening-time-wrapper"
                            id={`bridge-details-opening-hour-${parseStringForID(
                              time.validDates[0].date.toDateString()
                            )}`}
                          >
                            <div className="opening-time">
                              <div className="day-row">
                                <div className="dates">
                                  {time.validDates.map((d, i) => (
                                    <div
                                      className="date"
                                      key={d.isHoliday ? d.isHoliday.map(h => h.name).join(';') : i}
                                    >
                                      {i !== 0 ? '& ' : ''}
                                      {d.textFormatted}{' '}
                                      {d.isHoliday &&
                                        `(${d.isHoliday.map(h => h.name).join(', ')})`}
                                    </div>
                                  ))}
                                </div>
                                <div className="rules">
                                  {time.validRulesForToday.length === 0 && (
                                    <div
                                      className="rule"
                                      id={`bridge-details-opening-hour-${parseStringForID(
                                        time.validDates[0].date.toDateString()
                                      )}-no-service`}
                                    >
                                      {I18n.t(
                                        'routeList.singleItem.bridge_operating_rules.noService'
                                      )}
                                    </div>
                                  )}
                                  {time.validRulesForToday.map((rule, ruleKey) => (
                                    <div
                                      className="rule"
                                      key={ruleKey}
                                      id={`bridge-details-opening-hour-${parseStringForID(
                                        time.validDates[0].date.toDateString()
                                      )}-${ruleKey}`}
                                    >
                                      <div className="time">
                                        {rule.timespan === '00:00 - 23:59'
                                          ? I18n.t('routeList.singleItem.wholeDay')
                                          : rule.timespan}
                                      </div>
                                      <div className="note">
                                        {rule.notes?.map((subNote, i) => (
                                          <div className="subNote" key={i}>
                                            {subNote}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {singleItem.waterLevel && (
                      <div>
                        <b>{I18n.t('routeList.singleItem.waterLevel')}</b>:{' '}
                        {Math.round(singleItem.waterLevel.heightInCm) / 100} m [
                        {I18n.t('routeList.singleItem.NAP')}] (
                        {formatDateTimeShort(singleItem.waterLevel.timestamp, 'GB')})
                      </div>
                    )}

                    {notes.map((note, noteIndex) => (
                      <div key={noteIndex} id={`bridge-details-note-${noteIndex}`}>
                        <b>
                          {I18n.t('routeList.singleItem.note')}
                          {notes.length > 1 ? ' ' + (noteIndex + 1) : ''}
                        </b>
                        : {note}
                      </div>
                    ))}

                    {singleItem.bridgeOpening.sort(keysrt('number')).map((opening, key) => (
                      <div key={key}>
                        <div className="route-item-caption">
                          <b>
                            {I18n.t('routeList.singleItem.opening')} {opening.number}:{' '}
                            {opening.name}
                          </b>
                        </div>
                        <div className="route-item-details-item">
                          <b>{I18n.t('routeList.singleItem.type')}:</b>{' '}
                          {I18n.t('routeList.singleItem.bridge_type.' + opening.type)}
                        </div>
                        {opening.width && (
                          <div className="route-item-details-item">
                            <b>{I18n.t('routeList.singleItem.opening_width')}:</b> {opening.width} m
                          </div>
                        )}
                        {opening.heightClosed && (
                          <div className="route-item-details-item">
                            <b>{I18n.t('routeList.singleItem.closed_height')}:</b>{' '}
                            {opening.heightClosed} m, [{singleItem.referencelevel}]
                          </div>
                        )}
                        {opening.heightOpened && (
                          <div className="route-item-details-item">
                            <b>{I18n.t('routeList.singleItem.opened_height')}:</b>{' '}
                            {opening.heightOpened} m
                          </div>
                        )}
                        {opening.note && (
                          <div className="route-item-details-item">
                            <b>{I18n.t('routeList.singleItem.bridgeOpeningNote')}:</b>{' '}
                            {opening.note}
                          </div>
                        )}
                      </div>
                    ))}
                  </section>
                )}
                <BridgeDrawing itemDetails={singleItem} />
              </div>
            </div>
          </Popup>
        )}
    </>
  )

  async function fetchItems() {
    try {
      const fetchItem = await teqplayApiService.fetchBridges(bounds)
      const refUuids = navigationRoute?.route?.routeItems
        .filter(item => item.type === 'BRIDGE')
        .map(item => item.refUuid)
      const routeOrDefault =
        navigationRoute && fetchItem
          ? fetchItem?.filter(g => !refUuids?.includes(g.isrsCode))
          : fetchItem
      setBridges(routeOrDefault)
    } catch (error) {
      console.log(error)
    }
  }
}

const BridgeMarker = (props: IBridgeMarker) => {
  const [loadedIcon, setLoadedIcon] = useState<boolean>(false)
  const defaultLayout = {
    'icon-image': props.category,
    'icon-allow-overlap': true,
    'icon-ignore-placement': true
  }

  const image = new Image(50, 50)
  image.onload = () => {
    setLoadedIcon(true)
  }
  image.src = props.iconUrl
  const specificIcon = [props.category, image]
  if (!loadedIcon) {
    return null
  }
  return (
    <Layer
      type="symbol"
      id={props.category}
      source={props.category}
      layout={defaultLayout}
      images={specificIcon}
      onMouseEnter={() => (props.cursor ? (props.cursor.style.cursor = 'pointer') : '')}
      onMouseLeave={() => (props.cursor ? (props.cursor.style.cursor = '') : '')}
    >
      {props.defaultItems.map(item => (
        <Feature
          coordinates={item.location.coordinates || item.location[0]?.coordinates}
          key={item._id}
          onClick={() => props.setSingleItem(item)}
        />
      ))}
    </Layer>
  )
}

const BridgeRadius = (props: IBridgeRadius) => {
  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: props.defaultItems.map(line => {
          const coordinates = line.location.coordinates || line.location[0]?.coordinates
          const isInsideCircle = insideCircle(
            props.currentUserLocation && props.currentUserLocation.location
              ? [
                  props?.currentUserLocation?.location?.latitude,
                  props?.currentUserLocation?.location?.longitude
                ]
              : [0, 0],
            { latitude: coordinates[0], longitude: coordinates[1] },
            250 || 0
          )
          return {
            type: 'Feature',
            properties: { color: isInsideCircle ? '#244994' : '#d98c32' },
            geometry: {
              type: 'Polygon',
              coordinates: circle(coordinates, 250, { steps: 50, units: 'meters' }).geometry
                .coordinates
            }
          }
        })
      }}
      fillPaint={{
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.6
      }}
    />
  )
}

export default BridgeLayer
