import { format } from 'date-fns'

// TO-DO: Add frisian datetime format
import fy_NL from 'date-fns/locale/fy'
import de_DE from 'date-fns/locale/de'
import en_GB from 'date-fns/locale/en-GB'
import nl_NL from 'date-fns/locale/nl'

import {
  BRIDGE_DATE_FORMAT,
  BRIDGE_SHORT_DATE_FORMAT,
  DATE_TIME_FORMAT,
  SHORT_DATE_FORMAT,
  SHORT_DATE_TIME_FORMAT,
  TIME_FORMAT
} from './constants'

export function identifyAndParseDate(date: number | string | Date) {
  if (typeof date === 'number') {
    if (isNaN(date) || date < 0) {
      throw new Error(`Could not parse date for number value ${date}`)
    } else {
      return new Date(date)
    }
  } else if (typeof date === 'string') {
    const newDate = Date.parse(date)

    if (isNaN(newDate)) {
      throw new Error(`Could not parse date for string value ${date}`)
    } else {
      return new Date(date)
    }
  } else if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.valueOf())) {
      throw new Error(`Could not parse date for Date type value ${date}`)
    } else {
      return date
    }
  } else {
    throw new Error(`Could not parse date ${date}`)
  }
}

export function formatTime(
  time: string | Date | number | null | undefined,
  locale?: string
): string {
  if (!time && time !== 0) {
    return '-'
  }

  return format(identifyAndParseDate(time), TIME_FORMAT, {
    locale: determineLocaleFileFromLocale(locale || 'nl_NL')
  })
}

export function formatDateTime(
  time: string | Date | number | null | undefined,
  locale: string
): string {
  if (!time && time !== 0) {
    return '-'
  }

  return format(identifyAndParseDate(time), DATE_TIME_FORMAT, {
    locale: determineLocaleFileFromLocale(locale)
  })
}

export function formatDateTimeShort(
  time: string | Date | number | null | undefined,
  locale: string
): string {
  if (!time && time !== 0) {
    return '-'
  }

  return format(identifyAndParseDate(time), SHORT_DATE_TIME_FORMAT, {
    locale: determineLocaleFileFromLocale(locale)
  })
}

export function formatDate(time: string | Date | null | undefined, locale: string): string {
  if (!time) {
    return '-'
  }

  return format(identifyAndParseDate(time), SHORT_DATE_FORMAT, {
    locale: determineLocaleFileFromLocale(locale)
  })
}

export function formatWeekdayDateTime(
  time: string | Date | null | undefined,
  locale: string
): string {
  if (!time) {
    return '-'
  }

  return format(identifyAndParseDate(time), BRIDGE_DATE_FORMAT, {
    locale: determineLocaleFileFromLocale(locale)
  })
}

export function formatWeekdayDateTimeShort(
  time: string | Date | null | undefined,
  locale: string
): string {
  if (!time) {
    return '-'
  }

  return format(identifyAndParseDate(time), BRIDGE_SHORT_DATE_FORMAT, {
    locale: determineLocaleFileFromLocale(locale)
  })
}

export function formatIntoHoursAndMinutesFromNow(etaTimestamp: number) {
  const now = new Date().valueOf()

  let days = Math.floor((etaTimestamp - now) / 86400000)
  let hours = Math.floor(((etaTimestamp - now) % 86400000) / 3600000)
  let minutes = Math.round(((etaTimestamp - now) % 3600000) / 60000)

  if (hours === 24) {
    days = +1
    hours = 0
  }

  if (minutes === 60) {
    hours = +1
    minutes = 0
  }

  return formatDayHourMinutes(days, hours, minutes)
}

export function formatDurationIntoHoursAndMinutes(
  durationInMinutes: number,
  showAllUnits?: boolean
) {
  let days = Math.floor(durationInMinutes / (24 * 60))
  let hours = Math.floor((durationInMinutes % (24 * 60)) / 60)
  let minutes = durationInMinutes % 60

  if (hours === 24) {
    days += 1
    hours = 0
  }

  if (minutes === 60) {
    hours = +1
    minutes = 0
  }

  return formatDayHourMinutes(days, hours, minutes, showAllUnits)
}

function formatDayHourMinutes(
  days: number,
  hours: number,
  minutes: number,
  showAllUnits?: boolean
) {
  // TO-DO: Put into i18n
  const localeStrings = { days: { singular: ' dag', plural: ' dagen' }, hours: 'h', minutes: 'min' }

  if (showAllUnits) {
    return days > 0
      ? `${days}${days === 1 ? localeStrings.days.singular : localeStrings.days.plural}${
          hours > 0 ? ` ${hours}${localeStrings.hours}` : ''
        }${minutes > 0 ? ` ${minutes}${localeStrings.minutes}` : ''}`
      : hours > 0
      ? `${hours}${localeStrings.hours}${minutes > 0 ? ` ${minutes}${localeStrings.minutes}` : ''}`
      : minutes > 0
      ? `${minutes}${localeStrings.minutes}`
      : null
  } else {
    if (days > 0) {
      return `${days}${days === 1 ? localeStrings.days.singular : localeStrings.days.plural}`
    } else if (hours > 0) {
      return `${hours}${localeStrings.hours}${
        minutes > 0 ? ` ${minutes}${localeStrings.minutes}` : ''
      }`
    } else if (minutes > 0) {
      return `${minutes}${localeStrings.minutes}`
    } else {
      return null
    }
  }
}

function determineLocaleFileFromLocale(locale: string) {
  switch (locale) {
    case 'nl_NL':
      return nl_NL
    case 'de_DE':
      return de_DE
    case 'frl_NL':
      return fy_NL
    case 'en_GB':
      return en_GB

    default:
      return nl_NL
  }
}
