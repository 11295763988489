import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { connect, MapDispatchToPropsFunction } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import GeoLocationWatcher from '../components/geoLocationWatcher/GeoLocationWatcher'
import CustomPage from '../pages/customPage/CustomPage'
import FeedbackPage from '../pages/feedbackPage/FeedbackPage'
import MyProfile from '../pages/myProfile/MyProfile'
import NavigationPage from '../pages/navigationPage/NavigationPage'
import RouteSelectionPage from '../pages/routeSelectionPage/RouteSelectionPage'

import { IExtendedPropertyLocation, IRootProps } from '../@types/types'
import { frieslandChannels } from '../assets/channels/frieslandChannels'
import * as crossingLocations from '../assets/vdjs/vdjsCrossings.json'
import { BridgeWatchdogComponent } from '../components/bridgeWatchdog/BridgeWatchdog'
import {
  IFullPosition,
  IRouteEtaUpdate,
  IShipLocationUpdate
} from '../components/geoLocationWatcher/GeoLocationWatcherTypes'
import { clearAuth, setAuth } from '../pages/loginPage/actions'
import { stopCurrentRoute } from '../pages/routeSelectionPage/actions'
import { IAuthenticationWrapper } from '../services/AuthenticationWrapper/AuthenticationWrapper'
import { IUserAuth } from '../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../services/TeqplayApiService/TeqplayApiService'
import { initFirebase } from '../utils/fcmUtils'
import { sendExceptionToSentry } from '../utils/sentry'
import { setCurrentLocation, setLocationHistory } from './actions'
import ProtectedRoute, { IRoleType } from './ProtectedRoute'
import Policy from '../pages/policyPage/policyPage'

interface IDispatchProps {
  clearAuth: () => void
  setAuth: (userAuth: IUserAuth) => void
  setCurrentLocation: (location: IFullPosition) => void
  stopCurrentRoute: (teqplayApiService: TeqplayApiService) => void
  setUserHistory: (history: IExtendedPropertyLocation[]) => void
}

interface IState {
  sessionID: string
}

class Routes extends React.PureComponent<
  IRootProps & IDispatchProps & RouteComponentProps<null> & IAuthenticationWrapper,
  IState
> {
  constructor(
    props: IRootProps & IDispatchProps & RouteComponentProps<null> & IAuthenticationWrapper
  ) {
    super(props)
    this.state = {
      sessionID: `session-${new Date().valueOf()}`
    }
  }

  public componentDidMount() {
    // Users are registered by default so should be done in Routes
    initFirebase(this.props.teqplayApiService)

    // Initialize sentry with data from cache
    Sentry.getCurrentScope().setUser({
      id: this.props.currentUser.teqplayId || undefined,
      username: this.props.currentUser.userName || undefined,
      token: this.props.currentUser.token || undefined,
      refreshToken: this.props.currentUser.refreshToken || undefined,
      timestamp: Date.now(),
      loggedOut: this.props.currentUser.token ? false : true
    })
  }

  public componentDidCatch(error: Error, errorInfo: any) {
    sendExceptionToSentry(error, undefined, errorInfo)
  }

  public render() {
    const { currentUser } = this.props
    if (!currentUser || !currentUser.token) {
      return <Redirect to="/login" push />
    }

    const routeProps = {
      role: this.props.teqplayApiService.isAnonymousUser()
        ? 'ANONYMOUS'
        : ('REGISTERED' as IRoleType),
      redirectHome: () => this.props.history.push('/' + this.props.location.search)
    }

    return (
      <GeoLocationWatcher
        routeSelection={this.props.routeSelection.navigationRoute}
        setCurrentLocation={this.setCurrentLocation}
        isNavigating={this.props.location.pathname === '/navigation'}
        VDJSCrossings={crossingLocations}
        channelCrossings={frieslandChannels}
        getSelectedRouteEtaUpdate={this.getSelectedRouteEtaUpdate}
        currentLocation={this.props.userLocation.currentLocation}
        isAnonymous={this.props.teqplayApiService.isAnonymousUser()}
        userHistory={this.props.userLocation.history}
        setUserHistory={this.props.setUserHistory}
        sessionID={this.state.sessionID}
      >
        {(VDJSNotificationMessage, channelApproachNotificationMessage, geoLocationWatcherState) => (
          <BridgeWatchdogComponent api={this.props.teqplayApiService}>
            {status => (
              <Switch location={this.props.location}>
                <ProtectedRoute path="/navigation" allowedRole={'REGISTERED'} {...routeProps}>
                  <Route
                    exact
                    path="/navigation"
                    render={(props: any) => (
                      <NavigationPage
                        {...props}
                        teqplayApiService={this.props.teqplayApiService}
                        VDJSNotificationMessage={VDJSNotificationMessage}
                        channelApproachNotificationMessage={channelApproachNotificationMessage}
                        watchdogStatus={status}
                        geoLocationWatcherState={geoLocationWatcherState}
                      />
                    )}
                  />
                </ProtectedRoute>

                <ProtectedRoute path="/myProfile" allowedRole={'REGISTERED'} {...routeProps}>
                  <Route
                    exact
                    path="/myProfile"
                    render={(props: any) => (
                      <CustomPage
                        isAnonymous={this.props.teqplayApiService.isAnonymousUser()}
                        userName={currentUser.userName}
                        location={this.props.location}
                        onLogout={this.handleLogout}
                      >
                        <MyProfile {...props} teqplayApiService={this.props.teqplayApiService} />
                      </CustomPage>
                    )}
                  />
                </ProtectedRoute>

                <ProtectedRoute path="/feedback" allowedRole={'REGISTERED'} {...routeProps}>
                  <Route
                    exact
                    path="/feedback"
                    render={props => (
                      <CustomPage
                        isAnonymous={this.props.teqplayApiService.isAnonymousUser()}
                        userName={currentUser.userName}
                        location={this.props.location}
                        onLogout={this.handleLogout}
                        disableStyling={true}
                      >
                        <FeedbackPage
                          {...props}
                          userLocation={this.props.userLocation}
                          teqplayApiService={this.props.teqplayApiService}
                        />
                      </CustomPage>
                    )}
                  />
                </ProtectedRoute>
                <Route exact path="/policy" component={Policy} />
                <Route
                  render={(props: any) => (
                    <RouteSelectionPage
                      teqplayApiService={this.props.teqplayApiService}
                      currentLocation={this.props.userLocation.currentLocation}
                      isAnonymous={routeProps.role === 'ANONYMOUS'}
                      watchdogStatus={status}
                      geoLocationWatcherState={geoLocationWatcherState}
                      {...props}
                    />
                  )}
                />
              </Switch>
            )}
          </BridgeWatchdogComponent>
        )}
      </GeoLocationWatcher>
    )
  }

  public handleLogout = () => {
    this.props.teqplayApiService.logoutUser()
  }

  /**
   * Syncs the users current location with the redux store and
   * sends the users current location to the backend.
   */
  public setCurrentLocation = async (location: IFullPosition, appInBackground?: boolean) => {
    // sets the location in the redux store
    this.props.setCurrentLocation(location)

    // Only set the location in the backend in case the user is not Anonymous
    if (!this.props.teqplayApiService.isAnonymousUser()) {
      // update backend based on the new location
      try {
        // Add TeqplayId To the ship update call.
        const locationUpdate: IShipLocationUpdate = {
          ...location,
          teqplayId: this.props.currentUser.teqplayId
        }

        // Only send locations if app is in the foreground
        // or whenever there is an active route
        if (
          !appInBackground ||
          (this.props.routeSelection.navigationRoute &&
            this.props.routeSelection.navigationRoute !== null &&
            this.props.routeSelection.navigationRoute.paused === false)
        ) {
          await this.props.teqplayApiService.setCurrentPosition(locationUpdate)
        }
      } catch (error) {
        console.error(error)
        sendExceptionToSentry(error)
      }
    }
  }

  public getSelectedRouteEtaUpdate = async (): Promise<IRouteEtaUpdate | undefined> => {
    try {
      const etaUpdate = await this.props.teqplayApiService.getSelectedRouteEtaUpdate()
      return etaUpdate
    } catch (error) {
      console.error(error)
      sendExceptionToSentry(error)

      // Route was cancelled by the backend, process it in the frontend
      if (error.status === 404) {
        console.error('Route cancelled by backend, stopping route')
        this.props.stopCurrentRoute(this.props.teqplayApiService)
      }

      return
    }
  }
}

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, any> = (
  dispatch: ThunkDispatch<IRootProps, void, Action>
) => {
  return {
    clearAuth: () => dispatch(clearAuth()),
    setAuth: (user: IUserAuth) => dispatch(setAuth(user)),
    setCurrentLocation: (location: IFullPosition) => dispatch(setCurrentLocation(location)),
    stopCurrentRoute: (teqplayApiService: TeqplayApiService) =>
      stopCurrentRoute(dispatch, teqplayApiService),
    setUserHistory: (history: IExtendedPropertyLocation[]) => dispatch(setLocationHistory(history))
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
