import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Modal from 'react-modal'
import Popup from 'react-popup'
import { Provider } from 'react-redux'
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import LoginPageContainer from './pages/loginPage/LoginPageContainer'
import RegisterPage from './pages/registerPage/RegisterPage'
import PolicyPage from './pages/policyPage/policyPage'
import Routes from './routes/Routes'
import AuthenticationWrapper from './services/AuthenticationWrapper/AuthenticationWrapper'

import CordovaWrapper from './components/cordovaWrapper/CordovaWrapper'
import EndSupportPopup from './components/endSupportPopup/EndSupportPopup'
import configureStore from './utils/configureStore'
import { isCordovaApp } from './utils/cordovaUtils'
import { initializeSentry } from './utils/sentry'

import 'mapbox-gl/src/css/mapbox-gl.css'
import 'react-toastify/dist/ReactToastify.css'
import './style/index.scss'
import './style/popup-styling.scss'

import de_DE from './translations/de_DE'
import en_GB from './translations/en_GB'
import frl_NL from './translations/frl_NL'
import nl_NL from './translations/nl_NL'

import { loadFromLocalStorage } from './utils/localStorage'
Modal.setAppElement('#root')

function onDeviceReady() {
  const { store, persistor } = configureStore()
  initializeSentry()

  // Translations
  syncTranslationWithStore(store)
  store.dispatch(
    loadTranslations({
      nl_NL,
      frl_NL,
      en_GB,
      de_DE
    }) as any
  )
  store.dispatch(setLocale(loadFromLocalStorage('locale', 'nl_NL')) as any)

  if (window.cordova) {
    const devicePlatform = window.device ? window.device.platform : 'desktop'

    if (devicePlatform === 'Android') {
      document.addEventListener(
        'backbutton',
        e => {
          e.preventDefault()
          ;(navigator as any).app.exitApp()
        },
        false
      )
    }

    // Do not edit any cordova plugins here, there is an off chance that they have not loaded yet
    // Especially on Android, causing an endless lockscreen
  }

  ReactDOM.render(
    <CordovaWrapper>
      {() => (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer autoClose={5000} transition={Slide} position="bottom-left" />
            <Popup
              closeBtn={true}
              escToClose={true}
              closeOnOutsideClick={true}
              closeHtml={<div className="icon-close_privacy" />}
            />
            <EndSupportPopup />
            <AuthenticationWrapper>
              {apiService => (
                <BrowserRouter>
                  <Switch>
                    <Route exact path="/login">
                      <LoginPageContainer teqplayApiService={apiService} />
                    </Route>
                    <Route exact path="/register">
                      <RegisterPage teqplayApiService={apiService} />
                    </Route>
                    <Route exact path="/policy">
                      <PolicyPage />
                    </Route>
                    <Route
                      render={routeProps => (
                        <Routes {...routeProps} teqplayApiService={apiService} />
                      )}
                    />
                  </Switch>
                </BrowserRouter>
              )}
            </AuthenticationWrapper>
          </PersistGate>
        </Provider>
      )}
    </CordovaWrapper>,
    document.getElementById('root')
  )
}

if (isCordovaApp) {
  document.addEventListener('deviceready', onDeviceReady, false)
} else {
  onDeviceReady()
}
